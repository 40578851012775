<div>
	<mat-card class="mat-elevation-z0">
		<mat-card-title class="flex justify-content-between flex-align-items-center">
			<div>{{ 'txPoAccounts.personal_accounts' | translate }}</div>
            <div>
                <button mat-button
                        mat-mini-fab
                        color="primary"
                        [matMenuTriggerFor]="settings"
                >
                    <fa-icon icon="cog"></fa-icon>
                </button>
                <mat-menu #settings="matMenu">
                    <div mat-menu-item>
                        <mat-slide-toggle (click)="toggleDayBalance($event)" [(ngModel)]="ShowDayBalance" color="primary">
                             {{ 'txPoAccounts.show_day_balance' | translate }}
                        </mat-slide-toggle>
                    </div>
                </mat-menu>
            </div>
		</mat-card-title>
		<mat-card-subtitle>
			<div class="mt-4" *ngIf="workflowGroups$ | async as workflowGroups">
				<mat-button-toggle-group
					(change)="toggleGroup($event)"
					name="workflowGroups"
					aria-label="Workflow Gruppen"
					multiple>
					<mat-button-toggle
						*ngFor="let group of workflowGroups"
						class="workflow-toggle-button"
						[value]="group.id"
						[checked]="SelectedGroups.includes(group.id)"
						>{{ group.name }}</mat-button-toggle
					>
				</mat-button-toggle-group>
			</div>
		</mat-card-subtitle>
		<mat-card-content *ngIf="workflows$ | async as workflows">
                <full-calendar #calendar [deepChangeDetection]="true" [options]="CalendarOptions"> </full-calendar>
                <div *ngIf="isButtonVisible" class="bg-white border-solid border-2 rounded z-index-1000" [ngStyle]="{'position': 'absolute', 'top.px': mouseY, 'left.px': mouseX}">
                    <button mat-button style="display: block; width: 100%" (click)="openFzeDialog()">{{ 'txApplyBooking.missingTime' | translate }}</button>
                    <button mat-button style="display: block; width: 100%" (click)="openTypeDialog()">PZE / Auftrag</button>
                </div>
		</mat-card-content>
	</mat-card>
</div>

