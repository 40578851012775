<div class="grid">
	<div class="col-12">
		<p-accordion (onOpen)="onTabOpen('w1')" (onClose)="onTabClose('w1')">
			<p-accordionTab
				[header]="'txWorkflow.calender' | translate"
				[selected]="tabState?.w1 == null || tabState?.w1 == true"
                (mouseenter)="unwatchWorkflowCalendar()" (mouseleave)="watchWorkflowCalendar()"
            >
				<section class="widget">
					<div *ngIf="myWorkflowCalendar == null">Daten werden verarbeitet, bitte warten...</div>
					<div *ngIf="myWorkflowCalendar != null">
						<tx-workflow-calendar [CalendarData]="myWorkflowCalendar" (OnRefresh)="refresh()"></tx-workflow-calendar>
					</div>
				</section>
			</p-accordionTab>
		</p-accordion>
	</div>
</div>
<div class="grid flex-column-reverse md:flex-row">
	<div class="col-12 xl:col-9 pull-xl-3">
		<p-accordion (onOpen)="onTabOpen('w4')" (onClose)="onTabClose('w4')">
			<p-accordionTab
				[header]="'txWorkflow.requests' | translate"
				[selected]="tabState?.w4 == null || tabState?.w4 == true ? true : false"
                (mouseenter)="unwatchWorkflow()" (mouseleave)="watchWorkflow()"
            >
				<div class="scroll-indicator">
					<i class="fa fa-angle-right blink"></i>
					<i class="fa fa-angle-right blink"></i>
					<i class="fa fa-angle-right blink"></i>
				</div>
				<section class="widget">
					<div *ngIf="!myWorkflowHasData">{{ 'txWorkflow.noRequests' | translate }}</div>

					<div *ngIf="myWorkflowHasData">
						<div class="widget-body">
							<p-table
								[value]="Workflow"
								styleClass="table table-hover"
								[paginator]="true"
								[rows]="20"
								[responsive]="true">
								<ng-template pTemplate="header">
									<tr>
										<th>Datum</th>
										<th>Typ</th>
										<th>Name</th>
										<th>Bearbeiter</th>
										<th>Notiz</th>
										<th>Antwort</th>
										<th>Status</th>
										<th></th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-item>
									<tr [ngClass]="{
									    'border-bottom-none': item.info
									}">
										<td class="align-items-start">
											<span class="p-column-title">Datum</span>
											<div class="md:inline-block sm:flex sm:flex-column">
												<div>
													<ng-template [ngIf]="checkBisData(item.bis)">
														<strong>Von:</strong> {{ item.von | date: 'EE dd.MM.yyyy' }}<br />
													</ng-template>
													<ng-template [ngIf]="!checkBisData(item.bis)">
														<strong>Am:</strong> {{ item.von | date: 'EE dd.MM.yyyy' }}<br />
													</ng-template>
												</div>
												<div>
													<ng-template [ngIf]="checkBisData(item.bis)">
														<strong>Bis:&nbsp;</strong> {{ item.bis ? (item?.bis | date: 'EE dd.MM.yyyy') : '' }}
													</ng-template>
													<ng-template [ngIf]="!checkBisData(item.bis)">
														<strong>um:&nbsp;</strong> {{ item?.von | formatTime: 'HH:mm' }}
													</ng-template>
												</div>
											</div>
										</td>
										<td><span class="p-column-title">Typ</span>{{ item.Typname }}{{ item.TypNeu }}</td>
										<td class="wrap"><span class="p-column-title">Name</span><span [innerHTML]="item.Name"></span></td>
										<td class="wrap"><span class="p-column-title">Bearbeiter</span><span [innerHTML]="item.Bearbeiter"></span></td>
										<td class="wrap">
											<span class="p-column-title">Notiz</span><span [innerHTML]="item.NotizAntragssteller"></span>
										</td>
										<td class="wrap">
											<span class="p-column-title">Antwort</span><span [innerHTML]="item.NotizAntragsbearbeiter"></span>
										</td>
										<td>
											<span class="p-column-title">Status</span>
											{{ item.Status == 0 ? 'abgelehnt' : '' }}
											{{ item.Status == 1 ? 'genehmigt' : '' }}
											{{ item.Status == 99 ? 'offen' : '' }}
										</td>
										<td>
                                            <div *ngIf="item.art !== 'FSC'">
											<button
												type="submit"
												class="btn btn-primary btn-block"
												(click)="onDeleteClick(item)"
												*ngIf="item.Status == 99 && item.Eigener == 1">
												Löschen
											</button>
											<button
												type="submit"
												class="btn btn-primary btn-block"
												(click)="onConfirmClick(item)"
												*ngIf="item.Status != 99 && item.Eigener == 1">
												Bestätigen
											</button>
											<button
												type="submit"
												class="btn btn-primary btn-block"
												(click)="onApproveClick(item)"
												*ngIf="item.Status == 99 && item.Eigener == 2">
												Zustimmen
											</button>
											<button
												type="submit"
												class="btn btn-primary btn-block"
												(click)="onDeclineClick(item)"
												*ngIf="item.Status == 99 && item.Eigener == 2">
												Ablehnen
											</button>
                                            </div>
										</td>
									</tr>
<!--                                    <tr *ngIf="item.info" (mouseenter)="hoverBoth($event)" (mouseleave)="hoverBoth($event)">-->
<!--                                        <td colspan="7" class="wrap">-->
<!--                                            <strong>{{ 'txTeamleiter.info' | translate }}: </strong-->
<!--                                            ><span [innerHTML]="item.info"></span>-->
<!--                                        </td>-->
<!--                                    </tr>-->
								</ng-template>
							</p-table>
						</div>
					</div>
				</section>
			</p-accordionTab>
		</p-accordion>
	</div>
	<div class="col-12 xl:col-3">
		<p-accordion (onOpen)="onTabOpen('w2')" (onClose)="onTabClose('w2')">
			<p-accordionTab
				[header]="'txWorkflow.makeRequest' | translate"
				[selected]="tabState?.w2 == null || tabState?.w2 == true ? true : false">
				<section class="widget">
					<tx-apply-booking [myFormType]="antragForm" (submitClicked)="updateFromComponent()"></tx-apply-booking>
				</section>
			</p-accordionTab>
		</p-accordion>
		<p-accordion (onOpen)="onTabOpen('w3')" (onClose)="onTabClose('w3')">
			<p-accordionTab
				[header]="'txWorkflow.requestBookings' | translate"
				[selected]="tabState?.w3 == null || tabState?.w3 == true ? true : false">
				<section class="widget">
					<tx-apply-booking [myFormType]="buchungForm" (submitClicked)="updateFromComponent()"></tx-apply-booking>
				</section>
			</p-accordionTab>
		</p-accordion>
	</div>
</div>
