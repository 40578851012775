/* tslint:disable:variable-name */
import { TxWorkflows } from './workflow';

export class TxUpdatePassword {
	constructor(public NewPassword: string, public NewPasswordRepeat: string, public NewPasswordUndecoded: string) {}
}

export class TxSetting {
    constructor(public statuscode: number = 0, public statustext: string = '', public settingValue: number) {}
}
