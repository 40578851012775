import { Component, Inject } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog
} from '@angular/material/dialog';
import { TxSchedulerFzDialogComponent } from '../../../tx-scheduler/dialogs/tx-scheduler-fz/tx-scheduler-fz.dialog.component';
import {
    Observable,
    of
} from 'rxjs';
import {
    map,
    switchMap,
    take,
    withLatestFrom
} from 'rxjs/operators';
import { TlProtocolService } from '../../../../shared/services/tl-protocol.service';
import { ConnectionStateService } from '../../../../shared/services/connection-state.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare let Messenger: any;

export interface DialogData {
    dataId: number;
    personalNr: number;
}

@Component({
    templateUrl: './tx-teamleiter-fsc.dialog.component.html'
})
export class TxTeamleiterFscDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<TxTeamleiterFscDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private myTlprotocolService: TlProtocolService,
        public sanitizer: DomSanitizer,
        private connectionStateService: ConnectionStateService,
        public dialog: MatDialog) {

        this.OnlineState$ = connectionStateService.onlineState.pipe(
            map((online) => ({
                isOnline: online,
            }))
        );

        this.showDriverLicenseCheckDialog(data.dataId, data.personalNr);
    }

    OnlineState$: Observable<{ isOnline: boolean }>;

    isBusy: {
        [key: string]: boolean;
    } = {};
    currentDataId: number;
    driverLicenseCheck = false;
    refImage: string;
    refBase64: SafeResourceUrl;
    userImage: SafeResourceUrl;
    driverLicenseNotice: string;

    onNoClick(): void {
        if (this.userImage) {
            URL.revokeObjectURL(this.userImage as string);
        }
        this.dialogRef.close();
    }

    showDriverLicenseCheckDialog(dataId: number, personalNr: number) {


        this.isBusy[dataId] = true;
        this.currentDataId = dataId;

        this.myTlprotocolService
            .getReferenceData(dataId, personalNr)
            .pipe(
                switchMap((data) => {
                    const intermediate = this.connectionStateService.isIntermediateSubject.getValue();
                    if (intermediate) {
                        return this.connectionStateService.intermediateState.pipe(
                            map(() => {
                                return data;
                            })
                        );
                    }
                    return of(data);
                }),
                withLatestFrom(this.OnlineState$),
                take(1)
            )
            .subscribe(async ([result, state]) => {
                this.isBusy[dataId] = false;
                if (!state?.isOnline) {
                    Messenger().message(
                        'Aktuell zu schlechte Netzwerkverbindung, diese Funktion ist daher vorübergehend nicht verfügbar.',
                        'info'
                    );
                    return;
                }
                if (result.statuscode === 0) {
                    this.driverLicenseCheck = true;
                    this.refImage = 'data:image/jpeg;base64,' + result.ref_data;
                    /*this.refBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
                        'data:application/pdf;base64,' + result.ref_data
                    );*/
                    this.refBase64 = Buffer.from(result.ref_data, 'base64');
                    this.userImage = this.sanitizer.bypassSecurityTrustResourceUrl(
                        URL.createObjectURL((await fetch(`data:application/pdf;base64,${result.user_data}`).then(res => res.blob()))) + '#navpanes=0'
                    );
                    //this.userImage = 'data:application/pdf;base64,' + result.user_data;
                } else {
                    Messenger().post({
                        message: result.statustext,
                        type: 'error',
                        showCloseButton: true,
                    });
                }
            });
    }

    confirmDriverLicenseCheck(status: number) {
        this.myTlprotocolService
            .updateTlProtocol(this.currentDataId, status, 0, 'FSCHECK', status, this.driverLicenseNotice)
            .subscribe(
                (result) => {
                    this.driverLicenseCheck = false;

                    Messenger().post({
                        message: result.statustext,
                        type: 'success',
                        showCloseButton: true,
                        hideAfter: 5,
                    });
                },
                (error) => {
                    console.log('ERROR' + error);
                    Messenger().post({
                        message: 'ERROR' + error,
                        type: 'error',
                        showCloseButton: true,
                    });
                },
                () => {
                    this.driverLicenseNotice = '';
                }
            );
    }
}
