import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { TxApiService } from '../../shared/services/txapi.service';
import { TxSession, TxUpdateStellvertreter } from '../../shared/interfaces/session';
import { TabStates, TabStateService } from '../../shared/services/tabstate.service';

import { ListOfWorkflowGroup, TxUpdateWorkflowRequest, TxWfCalendar } from '../../shared/interfaces/workflow';
import { AuthService } from '../../shared/services/auth.service';
import { WorkflowService } from '../../shared/services/workflow.service';
import { NavigationEnd, Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { getTeamleaderCalendar } from '../../store/selectors/teamleader.selectors';
import { GetTeamleaderCalendar } from '../../store/actions/teamleader.actions';
import { TxWorkflowCalendarComponent } from '../tx-workflow-calendar/tx-workflow-calendar.component';
import { HeartbeatService } from '../../shared/services/heartbeat.service';
import { TxTeamleiterFscDialogComponent } from './dialogs/tx-teamleiter-fsc/tx-teamleiter-fsc.dialog.component';
import { MatDialog } from '@angular/material/dialog';

declare let Messenger: any;

@Component({
	selector: 'tx-teamleiter',
	templateUrl: './txteamleiter.component.html',
	styleUrls: ['./txteamleiter.component.scss'],
	providers: [TabStateService],
})
export class TxTeamleiterComponent implements OnInit {
	constructor(
		private myTxApiservice: TxApiService,
		private myAuthService: AuthService,
		private myTabStateService: TabStateService,
		private myWorkflowService: WorkflowService,
		private router: Router,
		private store: Store<State>,
		private hearbeatService: HeartbeatService,
        public dialog: MatDialog
	) {
		this.myWorkflowCalendar$ = this.store.select(getTeamleaderCalendar).pipe(
			tap((calendar) => {
				this.calendarIsBusy = false;
				this.myWorkflowCalendar = calendar?.map((c) =>
					Object.assign(
						{},
						{
							...c,
							ListOfDateItem: c.ListOfDateItem?.map((d) => Object.assign({}, d)) ?? [],
						}
					)
				);
			})
		);

		this.router.events.subscribe((evt) => {
			if (evt instanceof NavigationEnd && evt.url.indexOf('teamleiter') > -1) {
				// trick the Router into believing it's last link wasn't previously loaded
				this.calendar?.jumpToToday();
			}
		});
	}
	tabState: TabStates;

	Workflow;
	myWorkflowCalendar: TxWfCalendar[];
	myWorkflowCalendar$: Observable<TxWfCalendar[]>;

	myWorkflowHasData = false;
	myTeamleaderState = false;
	session: TxSession;
	wfGroups: Array<ListOfWorkflowGroup> = null;
	calendarReady = false;
	calendarIsBusy = false;
	workflowDataNote: {
		[key: number]: string;
	} = {};

	@ViewChild('txWorkflowCalendarComponent')
	calendar?: TxWorkflowCalendarComponent;

	private lockWatch: {
		[key: string]: boolean;
	} = {};

	isCalendarReady(event) {
		this.calendarReady = true;
	}

    openFscDialog(dataId: number, personalNr: number): void {
        const dialogRef = this.dialog.open(TxTeamleiterFscDialogComponent, {
            width: '100%',
            data: {dataId, personalNr}
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

	updateWorkflow(aWfRow, status, notiz) {
		this.unwatchWorkflow();
		const aWfRequest: TxUpdateWorkflowRequest = new TxUpdateWorkflowRequest(aWfRow.id, status, notiz); // 100=löschen
		let apiAnfrage: Observable<TxUpdateWorkflowRequest>;
		let myResponse: any;

		apiAnfrage = this.myTxApiservice.callAPI('updateWorkflow', aWfRequest);

		apiAnfrage.subscribe(
			(mySessionData) => {
				myResponse = mySessionData;
				let responseStatus: string;
				if (myResponse.statuscode === 0) {
					responseStatus = 'success';
				} else {
					responseStatus = 'error';
				}
				Messenger().post({
					message: myResponse.statustext,
					type: responseStatus,
					showCloseButton: true,
					hideAfter: 5,
				});

				this.watchWorkflow();
			},
			(error) => {
				console.log('ERROR' + error);
				Messenger().post({
					message: 'ERROR' + error,
					type: 'error',
					showCloseButton: true,
				});

				this.watchWorkflow();
			}
		);
	}

	getWorkflowCalendar(fullyear = 0) {
		this.calendarIsBusy = true;
		this.myWorkflowCalendar = null;
		this.store.dispatch(GetTeamleaderCalendar(fullyear, 1));
		/*this.calendarIsBusy = true;
    this.myWorkflowCalendar = null;
    this.myWorkflowService.getWorkflowCalendar(fullyear, 1).subscribe(
      responseData => {
        this.myWorkflowCalendar = responseData.WfCalendar;
        this.calendarIsBusy = false;
      },
      error => {
        console.log('ERROR' + error);
      }
    );*/
	}

	getWorkflow() {
		this.myWorkflowService.getWorkflow(1).subscribe(
			(response) => {
				this.Workflow = response.Workflows;
				if (this.Workflow.length > 0) {
					this.myWorkflowHasData = true;
				}
			},
			(error) => {
				console.log('ERROR' + error);
			}
		);
	}

	onApproveClick(aWfRow: any, aNotiz?: string) {
		this.Workflow = this.Workflow.filter((elem) => elem !== aWfRow);
		this.updateWorkflow(aWfRow, 300, aNotiz); // 300=confirm

		this.watchWorkflow('input');
	}

	onDeclineClick(aWfRow: any, aNotiz?: string) {
		this.Workflow = this.Workflow.filter((elem) => elem !== aWfRow);
		this.updateWorkflow(aWfRow, 301, aNotiz); // 301=decline

		this.watchWorkflow('input');
	}

	checkBisData(bisData: any) {
		return bisData !== '';
	}

	toggleTeamleaderState(state: number, teamleader_for: number = 0) {
		const aUtRequest: TxUpdateStellvertreter = new TxUpdateStellvertreter(
			this.session.sessionid,
			state,
			teamleader_for
		);

		let myResponse: any;
		const apiAnfrage: Observable<TxUpdateStellvertreter> = this.myTxApiservice
			.callAPI('updateStellvertreter', aUtRequest)
			.pipe(take(1));

		apiAnfrage.subscribe(
			(myRequestData) => {
				myResponse = myRequestData;
				if (myResponse.statuscode !== 0) {
					Messenger().post({
						message: myResponse.statustext,
						type: 'error',
						showCloseButton: true,
					});
				} else {
					this.myTeamleaderState = !this.myTeamleaderState;
					localStorage.setItem('teamleaderChangedTo', String(this.myTeamleaderState));
				}
			},
			(error) => {
				console.log('ERROR' + error);
				Messenger().post({
					message: 'ERROR' + error,
					type: 'error',
					showCloseButton: true,
				});
			}
		);
	}

	onTabOpen(name) {
		this.myTabStateService.onTabOpen(name);
		switch (name) {
			case 't2':
				this.watchWorkflow();
				break;
		}
	}

	onTabClose(name) {
		this.myTabStateService.onTabClose(name);
		switch (name) {
			case 't2':
				this.unwatchWorkflow();
				break;
		}
	}

	getWorkflowGroups() {
		this.myWorkflowService.getWorkflowGroups().subscribe((data) => {
			this.wfGroups = data;
		});
	}

	ngOnInit() {
		// superseded by this.myTabStateService
		this.tabState = this.myTabStateService.getTabState();

		//this.getWorkflowCalendar();
		this.getWorkflow();

		this.session = this.myAuthService.getSession();
		if (this.session.workflow_version === 1) {
			this.getWorkflowGroups();
		}

		if (localStorage.getItem('teamleaderChangedTo')) {
			this.myTeamleaderState = localStorage.getItem('teamleaderChangedTo') === 'true';
		} else {
			this.myTeamleaderState = Boolean(this.session.stellvertreter_aktiv);
		}

		this.router.events.subscribe((evt) => {
			if (evt instanceof NavigationEnd && evt.url.indexOf('teamleiter') > -1) {
				// trick the Router into believing it's last link wasn't previously loaded
				//this.getWorkflowCalendar();
				this.getWorkflow();
			}
		});

		this.watchWorkflow();
	}

	hoverBoth($event: MouseEvent) {
		if ($event.type === 'mouseenter') {
			const prev = $event.target as Element;
			prev.previousElementSibling.classList.add('hover');
		} else {
			document.querySelectorAll('.bb-0').forEach((el) => el.classList.remove('hover'));
		}
	}

	private hasWatchLock() {
		return !!Object.entries(this.lockWatch).find((s) => s[1]);
	}

	public watchWorkflow(id?: string) {
		if (!id) {
			if (this.hasWatchLock()) {
				return;
			}
		} else if (this.lockWatch[id]) {
			this.lockWatch[id] = false;
		}
		if (this.myTabStateService.getTabState('t2')) {
			this.hearbeatService.addToHeartbeat(
				'teamleiter',
				() => {
					this.getWorkflow();
				},
				60
			);
		}
	}

	public unwatchWorkflow(id?: string) {
		if (!id) {
			if (this.hasWatchLock()) {
				return;
			}
		} else {
			this.lockWatch[id] = true;
		}

		this.hearbeatService.removeFromHeartbeat('teamleiter');
	}

	ngOnDestroy() {
		this.unwatchWorkflow();
	}
}
