<h1 mat-dialog-title>Führerscheinkontrolle</h1>
<div mat-dialog-content>
    <div class="grid p-2 overflow-y-auto" style="height: 100%">
        <div class="col-12 lg:col-6 flex flex-direction-column align-items-center" style="margin-bottom: 10px;">
            <h4 class="font-weight-bold">Referenz</h4>
            <pdf-viewer
                    [src]="refBase64"
                    [original-size]="false"
                    [fit-to-page]="true"
                    [autoresize]="true"
                    [show-borders]="false"
                    [zoom-scale]="'page-fit'"
                    style="width: 100%; padding-bottom: 75%; max-width: 500px;"
            ></pdf-viewer>
        </div>
        <div class="col-12 lg:col-6 flex flex-direction-column align-items-center" style="margin-bottom: 10px;">
            <h4 class="font-weight-bold">Mitarbeiterupload</h4>
            <object
                    [data]="userImage"
                    type="application/pdf"
                    width="100%"
                    style="min-height: 600px;"
                    height="100%"
                    title="Embedded PDF Viewer"
            >
                <iframe *ngIf="userImage" [src]="userImage"
                        style="height: 100%; aspect-ratio: 14/16;"></iframe>
            </object>
        </div>
    </div>
</div>

<div mat-dialog-actions class="flex justify-between">
    <mat-form-field appearance="outline" class="w-full mb-2" style="margin-bottom: -22px">
        <mat-label>Notiz</mat-label>
        <input matInput [(ngModel)]="driverLicenseNotice" autocomplete="new-password"/>
    </mat-form-field>
    <button mat-raised-button color="accent" (click)="onNoClick()">Abbrechen</button>
    <div class="button-group">
        <button mat-raised-button color="primary" (click)="confirmDriverLicenseCheck(1)">
            <i class="fa fa-check mr-2" aria-hidden="true"></i>
            Bestätigen
        </button>
        <button mat-raised-button color="primary" (click)="confirmDriverLicenseCheck(0)">
            <i class="fa fa-times mr-2" aria-hidden="true"></i>
            Ablehnen
        </button>
    </div>
</div>
