import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TxWorkflowCalendarComponent } from './tx-workflow-calendar/tx-workflow-calendar.component';
import { TxSchedulerComponent } from './tx-scheduler/tx-scheduler.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TxSharedModule } from '../shared/shared.module';
import { WLClockpickerComponent } from './wl-clockpicker/wl-clockpicker.component';
import { TxApplyBookingComponent } from './tx-apply-booking/tx-apply-booking.component';
import { TxBlockableDivComponent } from './tx-blockable-div/tx-blockable-div.component';
import { TxWelcomeComponent } from './tx-welcome/tx-welcome.component';
import { TxTeamleaderprotocolComponent } from './tx-teamleaderprotocol/tx-teamleaderprotocol.component';
import { TxImageuploadComponent } from './tx-imageupload/tx-imageupload.component';
import { TxBdeComponent } from './tx-bde/tx-bde.component';
import { TxCodeScannerComponent } from './tx-code-scanner/tx-code-scanner.component';
import { TxLoginComponent } from './tx-login/tx-login.component';
import { TxErrorComponent } from './tx-error/tx-error.component';
import { TxKalenderComponent } from './tx-kalender/txkalender.component';
import { TxTeamleiterComponent } from './tx-teamleiter/txteamleiter.component';
import { TxWorkflowComponent } from './tx-workflow/txworkflow.component';
import { TxBuchungenComponent } from './tx-buchungen/txbuchungen.component';
import { TxMyAccountComponent } from './tx-my-account/tx-my-account.component';
import { TxZeitkontoComponent } from './tx-zeitkonto/tx-zeitkonto.component';
import { TxPersonalFilesComponent } from './personaloffice/tx-personal-files/tx-personal-files.component';
import { ListboxModule } from 'primeng/listbox';
import { WidgetsModule } from '../widgets/widgets.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import ListContentComponent from '../layout/list-content/list-content.component';
import { TxTimemodelsComponent } from './tx-timemodels/tx-timemodels.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import TxBdeAssignmentComponent from './tx-bde-assignment/tx-bde-assignment.component';
import TxEventComponent from './tx-event/tx-event.component';
import { TxDayviewComponent } from './personaloffice/tx-dayview/tx-dayview.component';
import { NgxMaskModule } from 'ngx-mask';
import TxPoTimeAccountComponent from './personaloffice/tx-po-time-account/tx-po-time-account.component';
import { TxTimeBeamComponent } from './personaloffice/tx-time-beam/tx-time-beam.component';
import { MatMenuModule } from '@angular/material/menu';
import { TxProjectOfficeComponent } from './projectoffice/tx-project-office/tx-project-office.component';
import { TxProjectsOfficeComponent } from './projectoffice/tx-projects-office/tx-projects-office.component';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TxProjectOfficeWorkstepDialogComponent } from './projectoffice/tx-project-office-workstep-dialog/tx-project-office-workstep-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import TxProjectOfficeWorkstepsDialogComponent from './projectoffice/tx-project-office-worksteps-dialog/tx-project-office-worksteps-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { QrCodeModule } from 'ng-qrcode';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TxModalPdfComponent } from './tx-modal-pdf/tx-modal-pdf.component';
import { TxPlaningComponent } from './tx-planing/tx-planing.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { TxPlaningPlansDialogComponent } from './tx-planing/tx-planing-plans-dialog/tx-planing-plans-dialog.component';
import { TxPlaningPlanDialogComponent } from './tx-planing/tx-planing-plan-dialog/tx-planing-plan-dialog.component';
import {
	MAT_COLOR_FORMATS,
	NGX_MAT_COLOR_FORMATS,
	NgxMatColorPickerModule,
} from '@angular-material-components/color-picker';
import { TxPlaningSelectPlanDialogComponent } from './tx-planing/tx-planing-select-plan-dialog/tx-planing-select-plan-dialog.component';
import { CdkColumnDef } from '@angular/cdk/table';
import { TxPoAccountsComponent } from './personaloffice/tx-po-accounts/tx-po-accounts.component';
import { TxPersonalFilesDialogComponent } from './personaloffice/tx-personal-files/dialogs/tx-personal-files.dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TxMessageCenterComponent } from './tx-messagecenter/tx-messagecenter.component';
import { TxMessageCenterClientComponent } from './tx-messagecenter/client/tx-messagecenter-client.component';
import { TxChatComponent } from './tx-chat/tx-chat.component';
import { TxPersonalFilesTmocDialogComponent } from './personaloffice/tx-personal-files/time-model-one-changed/tx-personal-files.tmoc.dialog';
import { TxTimeTrackingDialogComponent } from './dialogs/tx-time-tracking/tx-time-tracking.dialog';
import TxTeamconfigurationComponent from './personaloffice/tx-teamconfiguration/tx-teamconfiguration.component';
import { DndModule } from 'ngx-drag-drop';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { TxCreateTeamLeaderDialogComponent } from './personaloffice/tx-teamconfiguration/dialogs/tx-create-teamleader/tx-create-team-leader.dialog.component';
import { TxSelectTypeDialogComponent } from './tx-scheduler/dialogs/tx-select-type-dialog/tx-select-type.dialog.component';
import { TxSchedulerFzDialogComponent } from './tx-scheduler/dialogs/tx-scheduler-fz/tx-scheduler-fz.dialog.component';
import { MatStepperModule } from '@angular/material/stepper';
import { TxSchedulerPzeComponent } from './tx-scheduler/tx-scheduler-pze/tx-scheduler-pze.component';
import { TxSchedulerBdeComponent } from './tx-scheduler/tx-scheduler-bde/tx-scheduler-bde.component';
import { TabStateService } from '../shared/services/tabstate.service';
import { TxWarningDialogComponent } from './tx-scheduler/dialogs/tx-warning-dialog/tx-warning.dialog.component';
import { TxBarcodeScannerComponent } from './tx-bde/dialogs/tx-barcode-scanner.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TxTeamleiterFscDialogComponent } from './tx-teamleiter/dialogs/tx-teamleiter-fsc/tx-teamleiter-fsc.dialog.component';

const components = [
	TxWorkflowCalendarComponent,
	WLClockpickerComponent,
	TxApplyBookingComponent,
	TxSchedulerComponent,
	TxBlockableDivComponent,
	TxWelcomeComponent,
	TxTeamleaderprotocolComponent,
	TxImageuploadComponent,
	TxBdeComponent,
	TxCodeScannerComponent,
	TxLoginComponent,
	TxZeitkontoComponent,
	TxMyAccountComponent,
	TxBuchungenComponent,
	TxWorkflowComponent,
	TxTeamleiterComponent,
	TxKalenderComponent,
	TxErrorComponent,
	TxPersonalFilesComponent,
	ListContentComponent,
	TxTimemodelsComponent,
	TxBdeAssignmentComponent,
	TxEventComponent,
	TxDayviewComponent,
	TxPoTimeAccountComponent,
	TxTimeBeamComponent,
	TxProjectOfficeComponent,
	TxProjectsOfficeComponent,
	TxProjectOfficeWorkstepDialogComponent,
	TxProjectOfficeWorkstepsDialogComponent,
	TxPersonalFilesTmocDialogComponent,
	TxModalPdfComponent,
	TxPlaningComponent,
	TxPlaningPlansDialogComponent,
	TxPlaningPlanDialogComponent,
	TxPlaningSelectPlanDialogComponent,
	TxPoAccountsComponent,
	TxPersonalFilesDialogComponent,
	TxMessageCenterComponent,
	TxMessageCenterClientComponent,
	TxChatComponent,
	TxTimeTrackingDialogComponent,
	TxTeamconfigurationComponent,
	TxCreateTeamLeaderDialogComponent,
    TxSchedulerFzDialogComponent,
    TxSchedulerPzeComponent,
    TxSchedulerBdeComponent,
    TxSelectTypeDialogComponent,
    TxWarningDialogComponent,
    TxBarcodeScannerComponent,
    TxTeamleiterFscDialogComponent
];

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		FormsModule,
		TxSharedModule,
		ReactiveFormsModule,
		RouterModule,
		ListboxModule,
		WidgetsModule,
		MatTabsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatCardModule,
		MatButtonModule,
		MatIconModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatExpansionModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatTooltipModule,
		MatDialogModule,
		MatTableModule,
		MatButtonToggleModule,
		MatCheckboxModule,
		NgxMaskModule.forChild(),
		MatMenuModule,
		MatSortModule,
		MatProgressSpinnerModule,
		MatPaginatorModule,
		MatSlideToggleModule,
		QrCodeModule,
		DragDropModule,
		MatRippleModule,
		MatGridListModule,
		NgxMatColorPickerModule,
		PdfViewerModule,
		DndModule,
		AngularStickyThingsModule,
		MatStepperModule,
		CommonModule,
		NgxMatSelectSearchModule,
	],
	exports: [...components, DndModule, AngularStickyThingsModule],
	providers: [{ provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }, CdkColumnDef, TabStateService],
	//schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
